import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'

export default class {
  static getInstance(store) {
    return new DataSource({
      store: new CustomStore({
        load: options => {
          const payload = {}
          if (options.searchValue) {
            payload.search = options.searchValue
          }
          if (options.filter && options.filter[0]) {
            // eslint-disable-next-line no-unused-vars
            const [field, operation, value] = options.filter[0]
            payload.search = value
          }

          if (options.sort && options.sort[0]) {
            const mapping = {
              user: 'user',
              created_at: 'created_at',
              first_order_date: 'first_order_date',
              last_order_loaded: 'last_order_loaded',
              status: 'status',
              shop_connected: 'shop_connected',
            }
            payload.sort_by = mapping[options.sort[0].selector] ?? options.sort[0].selector
            payload.sort_direction = options.sort[0].desc ? 'DESC' : 'ASC'
          }

          payload.take = options.take
          payload.skip = options.skip ?? 0

          return store.dispatch('shop/fetchList', payload).then(result => {
            if (!result.data) {
              return []
            }

            return {
              data: result.data,
              totalCount: result.total_items,
            }
          })
        },
      }),
      paginate: true,
      pageSize: 25,
      byKey(key) {
        return key
      },
    })
  }
}
