var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxDataGrid',{ref:"dataGrid",staticClass:"sxr-grid caption-left regular-text-color",attrs:{"data-source":_vm.datasource,"show-borders":false,"show-row-lines":true,"show-column-lines":false,"remote-operations":true,"word-wrap-enabled":true,"column-min-width":50,"column-auto-width":true,"allow-column-reordering":!_vm.isCurrentBreakPointXs,"width":"100%","height":"100%"},scopedSlots:_vm._u([{key:"actionsCellTemplate",fn:function(ref){
var cell = ref.data;
return [_c('DxDropDownButton',{attrs:{"split-button":false,"focus-state-enabled":false,"show-arrow-icon":false,"use-select-mode":false,"drop-down-options":{ width: 300 },"items":_vm.actions(cell),"display-expr":"text","icon":"overflow","width":"34"},on:{"item-click":_vm.onActionClick}})]}},{key:"ownerCellTemplate",fn:function(ref){
var cell = ref.data;
return [_c('OwnerCell',{attrs:{"value":cell.data.owner}})]}},{key:"marketplacesCellTemplate",fn:function(ref){
var cell = ref.data;
return [_c('MarketplacesCell',{attrs:{"value":cell.data.marketplaces}})]}},{key:"statusCellTemplate",fn:function(ref){
var cell = ref.data;
return [_c('StatusCell',{attrs:{"value":cell.data.status}})]}}])},[_c('DxHeaderFilter',{attrs:{"visible":false,"hide-select-all-on-search":false}}),_c('DxPaging',{attrs:{"page-size":25}}),_c('DxColumnChooser',{attrs:{"enabled":!_vm.isCurrentBreakPointXs,"mode":"select"}}),_c('DxExport',{attrs:{"enabled":false,"formats":['csv', 'xlsx'],"texts":{
      exportAll: 'Export to {0}'
    }}}),_c('DxScrolling',{attrs:{"mode":"virtual"}}),_c('DxStateStoring',{attrs:{"enabled":false,"type":"localStorage","storage-key":"users_dg_state_key_1"}}),_c('DxSearchPanel',{attrs:{"visible":true,"highlight-case-sensitive":true}}),_c('DxToolbar',[_c('DxItem',{attrs:{"location":"before","name":"searchPanel"}}),_c('DxItem',{attrs:{"name":"columnChooserButton"}}),_c('DxItem',{attrs:{"name":"exportButton"}})],1),_c('DxColumn',{attrs:{"data-field":"owner","caption":"Owner","sort-order":"asc","min-width":250,"cell-template":"ownerCellTemplate"}}),_c('DxColumn',{attrs:{"data-field":"marketplaces","caption":"Marketplaces","min-width":130,"allow-sorting":false,"cell-template":"marketplacesCellTemplate"}}),_c('DxColumn',{attrs:{"data-field":"status","caption":"Status","min-width":90,"cell-template":"statusCellTemplate"}}),_c('DxColumn',{attrs:{"data-field":"last_order_loaded","caption":"Last Order Loaded","min-width":180,"data-type":"datetime"}}),_c('DxColumn',{attrs:{"data-field":"daily_inventory_updated_at","caption":"Daily Inventory Updated At","min-width":260,"data-type":"datetime"}}),_c('DxColumn',{attrs:{"data-field":"created_at","caption":"Created At","min-width":180,"data-type":"datetime"}}),_c('DxColumn',{attrs:{"data-field":"number_orders_with_same_sku","caption":"Bad Orders","min-width":130,"data-type":"number"}}),_c('DxColumn',{attrs:{"data-field":"dashboard_opened_at","caption":"Dashboard Opened At","min-width":220,"data-type":"datetime"}}),_c('DxColumn',{attrs:{"data-field":"full_sync_finished_at","caption":"Full Sync Finished At","min-width":260,"data-type":"datetime"}}),_c('DxColumn',{attrs:{"show-in-column-chooser":false,"fixed":true,"fixed-position":"right","alignment":"center","css-class":"fixed-column-cell","type":"buttons","width":"70","cell-template":"actionsCellTemplate"}}),_c('DxColumn',{attrs:{"data-field":"first_order_date","caption":"First Order Date","min-width":170,"data-type":"datetime"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }