<template>
  <div>
    <DataGrid @on-sync-skipped-orders-click="onSyncSkippedOrdersClick" />
    <SyncSkippedOrdersModal
      v-if="shopId"
      v-model="isSyncSkippedOrdersModalVisible"
      :visible="isSyncSkippedOrdersModalVisible"
      :shop-id="shopId"
    />
  </div>
</template>

<script>

import DataGrid from '@/views/admin/shops/components/DataGrid.vue'
import SyncSkippedOrdersModal from '@/views/admin/shops/components/SyncSkippedOrdersModal.vue'

export default {
  name: 'ManageShops',
  components: {
    SyncSkippedOrdersModal,
    DataGrid,
  },
  data() {
    return {
      isSyncSkippedOrdersModalVisible: false,
      shopId: false,
    }
  },
  methods: {
    onSyncSkippedOrdersClick({ id }) {
      this.shopId = id
      this.isSyncSkippedOrdersModalVisible = true
    },
  },
}
</script>

<style scoped>

</style>
