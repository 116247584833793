import { render, staticRenderFns } from "./ManageShops.vue?vue&type=template&id=5eefe736&scoped=true&"
import script from "./ManageShops.vue?vue&type=script&lang=js&"
export * from "./ManageShops.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eefe736",
  null
  
)

export default component.exports