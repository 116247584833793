<template>
  <div class="d-flex align-center p-25">
    <b-avatar
      :src="value.avatar"
      variant="light-primary"
    >
      {{ avatarText(value.name || 'Shop Owner') }}
    </b-avatar>
    <div class="d-flex flex-column ml-25 p-25">
      <h6 class="text-base m-0">
        {{ value.name || 'Shop Owner' }}
      </h6>
      <small class="text-secondary">{{ value.email }}</small>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  name: 'OwnerCell',
  components: {
    BAvatar,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatarText,
    }
  },
}
</script>

<style scoped>

</style>
