<template>
  <DxDataGrid
    ref="dataGrid"
    class="sxr-grid caption-left regular-text-color"
    :data-source="datasource"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="false"
    :remote-operations="true"
    :word-wrap-enabled="true"
    :column-min-width="50"
    :column-auto-width="true"
    :allow-column-reordering="!isCurrentBreakPointXs"
    width="100%"
    height="100%"
  >
    <DxHeaderFilter
      :visible="false"
      :hide-select-all-on-search="false"
    />
    <DxPaging :page-size="25" />
    <DxColumnChooser
      :enabled="!isCurrentBreakPointXs"
      mode="select"
    />
    <DxExport
      :enabled="false"
      :formats="['csv', 'xlsx']"
      :texts="{
        exportAll: 'Export to {0}'
      }"
    />
    <DxScrolling
      mode="virtual"
    />
    <DxStateStoring
      :enabled="false"
      type="localStorage"
      storage-key="users_dg_state_key_1"
    />
    <DxSearchPanel
      :visible="true"
      :highlight-case-sensitive="true"
    />
    <DxToolbar>
      <DxItem
        location="before"
        name="searchPanel"
      />
      <DxItem
        name="columnChooserButton"
      />
      <DxItem
        name="exportButton"
      />
    </DxToolbar>
    <DxColumn
      data-field="owner"
      caption="Owner"
      sort-order="asc"
      :min-width="250"
      cell-template="ownerCellTemplate"
    />
    <DxColumn
      data-field="marketplaces"
      caption="Marketplaces"
      :min-width="130"
      :allow-sorting="false"
      cell-template="marketplacesCellTemplate"
    />
    <DxColumn
      data-field="status"
      caption="Status"
      :min-width="90"
      cell-template="statusCellTemplate"
    />
    <DxColumn
      data-field="last_order_loaded"
      caption="Last Order Loaded"
      :min-width="180"
      data-type="datetime"
    />
    <DxColumn
      data-field="daily_inventory_updated_at"
      caption="Daily Inventory Updated At"
      :min-width="260"
      data-type="datetime"
    />
    <DxColumn
      data-field="created_at"
      caption="Created At"
      :min-width="180"
      data-type="datetime"
    />
    <DxColumn
      data-field="number_orders_with_same_sku"
      caption="Bad Orders"
      :min-width="130"
      data-type="number"
    />
    <DxColumn
      data-field="dashboard_opened_at"
      caption="Dashboard Opened At"
      :min-width="220"
      data-type="datetime"
    />
    <DxColumn
      data-field="full_sync_finished_at"
      caption="Full Sync Finished At"
      :min-width="260"
      data-type="datetime"
    />
    <DxColumn
      :show-in-column-chooser="false"
      :fixed="true"
      fixed-position="right"
      alignment="center"
      css-class="fixed-column-cell"
      type="buttons"
      width="70"
      cell-template="actionsCellTemplate"
    />
    <DxColumn
      data-field="first_order_date"
      caption="First Order Date"
      :min-width="170"
      data-type="datetime"
    />
    <template #actionsCellTemplate="{ data: cell }">
      <DxDropDownButton
        :split-button="false"
        :focus-state-enabled="false"
        :show-arrow-icon="false"
        :use-select-mode="false"
        :drop-down-options="{ width: 300 }"
        :items="actions(cell)"
        display-expr="text"
        icon="overflow"
        width="34"
        @item-click="onActionClick"
      />
    </template>
    <template #ownerCellTemplate="{ data: cell }">
      <OwnerCell :value="cell.data.owner" />
    </template>
    <template #marketplacesCellTemplate="{ data: cell }">
      <MarketplacesCell :value="cell.data.marketplaces" />
    </template>
    <template #statusCellTemplate="{ data: cell }">
      <StatusCell :value="cell.data.status" />
    </template>
  </DxDataGrid>
</template>

<script>

import {
  DxColumn,
  DxColumnChooser, DxDataGrid, DxExport,
  DxHeaderFilter,
  DxItem, DxPaging, DxScrolling,
  DxSearchPanel,
  DxStateStoring, DxToolbar,
} from 'devextreme-vue/data-grid'
import { DxDropDownButton } from 'devextreme-vue/drop-down-button'
import ShopDatasource from '@/data/shops.datasource'
import StatusCell from '@/views/admin/shops/components/StatusCell.vue'
import MarketplacesCell from '@/views/admin/shops/components/MarketplacesCell.vue'
import OwnerCell from '@/views/admin/shops/components/OwnerCell.vue'

export default {
  name: 'DataGrid',
  components: {
    MarketplacesCell,
    StatusCell,
    DxDropDownButton,
    DxDataGrid,
    DxPaging,
    DxScrolling,
    DxExport,
    DxToolbar,
    DxHeaderFilter,
    DxColumnChooser,
    DxColumn,
    DxItem,
    DxStateStoring,
    DxSearchPanel,
    OwnerCell,
  },
  data() {
    return {
      datasource: ShopDatasource.getInstance(this.$store),
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
  },
  methods: {
    actions({ data }) {
      return [
        {
          id: data.id,
          text: 'Sync Skipped Orders',
          action: 'syncSkippedOrders',
          icon: 'sync',
        },
      ]
    },
    onActionClick({ itemData }) {
      switch (itemData.action) {
        case 'syncSkippedOrders':
          this.showSyncSkippedOrdersForm(itemData.id)
          break
        default:
      }
    },
    showSyncSkippedOrdersForm(id) {
      this.$emit('on-sync-skipped-orders-click', {
        id,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/common/grid.scss';
.dark-layout .dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #404656;
  background: #353d55;
}
</style>
<style scoped>

</style>
