<template>
  <div class="d-flex align-items-baseline justify-content-start tw-gap-2">
    <country-flag
      v-for="data in value"
      :key="data.country_code"
      :country="data.country_code"
      :title="data.country"
      :alt="data.country"
    />
  </div>
</template>
<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: 'MarketplacesCell',
  components: {
    CountryFlag,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
