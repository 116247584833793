<template>
  <b-badge :variant="variant">
    {{ title(value.replace('_', ' ')) }}
  </b-badge>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { title } from '@core/utils/filter'

export default {
  name: 'StatusCell',
  components: {
    BBadge,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title,
    }
  },
  computed: {
    variant() {
      if (this.value === 'active') {
        return 'light-success'
      }

      if (this.value === 'pending') {
        return 'light-warning'
      }

      if (this.value === 'suspended') {
        return 'light-danger'
      }

      return 'light-secondary'
    },
  },
}
</script>

<style scoped>

</style>
