<template>
  <b-modal
    v-model="isVisible"
    title="Sync Skipped Orders"
  >
    <date-range
      v-model="dateRange"
      :hide-ranges="true"
      :disable-range-label="true"
      @update="onChangeDateRange"
    />
    <template #modal-footer="{ cancel }">
      <div class="d-flex flex-column flex-lg-row flex-md-row flex-sm-row flex-1 justify-content-between">
        <div />
        <div class="mt-1 mt-sm-0 mt-lg-0 mt-xl-0">
          <b-button
            class="mr-1"
            style="width: 140px"
            variant="success"
            :disabled="isLoading"
            @click="onSubmitClick"
          >
            <span v-if="!isLoading">Submit</span>
            <span v-else>
              <b-spinner
                class="mr-1"
                small
                variant="light"
              />
              Submit
            </span>
          </b-button>
          <b-button
            :disabled="isLoading"
            @click="cancel()"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { BButton, BModal, BSpinner } from 'bootstrap-vue'
import moment from 'moment'
import DateRange from '@/components/formElements/DateRange.vue'

export default {
  name: 'SyncSkippedOrdersModal',
  components: {
    BSpinner,
    BButton,
    BModal,
    DateRange,
  },
  props: {
    visible: {
      type: Boolean,
    },
    shopId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      dateRange: {
        startDate: moment().subtract(1, 'days').toDate(),
        endDate: moment().subtract(1, 'days').toDate(),
      },
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    onSubmitClick() {
      this.isLoading = true
      this.$store.dispatch('shop/syncSkippedOrders', {
        shop_id: this.shopId,
        start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
      }).then(() => {
        this.isVisible = false
      }).catch(() => {
        this.$bvToast.toast('Something went wrong. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    onChangeDateRange(value) {
      this.dateRange = value
    },
  },
}
</script>

<style scoped>

</style>
